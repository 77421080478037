import React, { useEffect, useLayoutEffect, useState, useMemo } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import Modal from "react-modal"
import OtpInput from "react-otp-input"
import gql from "graphql-tag"
import NumberFormat from "react-number-format"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import { submitUtm, extractCookie } from "../utils/handle-utm"

import {
  GET_PROJECT_DETAIL,
  GET_NEARBY_PROJECT,
  FETCH_EBROCHURE,
} from "../graphql/development-graphql"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import { Button } from "../components/buttons/buttons"
import { SocmedLinks } from "../components/socmed-links/socmed-links"
import { CoverMain } from "../components/cover-main/cover-main"
import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs"
import { HeadingBasic } from "../components/heading/heading"
import { Section, Container } from "../components/grid/grid"
import { GoogleMap, GoogleMapsEmbed } from "../components/google-map/google-map"
import { Cover } from "../components/cover/cover"
import {
  Stats, 
  Carousel,
} from "../components/utils/render-functions"
import {
  WpGroupProjectDetail,
  WpIconlistNew,
  UnitTypes,
} from "../components/utils/render-functions-new"
import CalculatorFinance from "../components/calculator-finance"
import { Glider, GliderVirtualLink, GliderVirtualLinkNew } from "../components/glider/glider"
import { Button } from "../components/buttons/buttons"
import { NavTabs, TabPane } from "../components/ak-tabs/tabs"

import icBus from "../assets/img/icons/ic-bus-white.png"
import icTrain from "../assets/img/icons/ic-train.png"
import icToll from "../assets/img/icons/ic-toll-white.png"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const STATIC_IMAGE = graphql`
  query {
    imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const GET_OTP = gql`
  mutation getOtp($phoneNumber: String) {
    requestOtp(input: { clientMutationId: "", phoneNumber: $phoneNumber }) {
      status
      message
    }
  }
`

const SUBMIT_OTP = gql`
  mutation SubmitFormInfo(
    $fullName: String
    $email: String
    $phoneNumber: String
    $otpCode: String
    $propertyName: String
    $visitDate: String
    $utm: String
    $projectsCode: String
    $clusterCode: String
  ) {
    submit2FormGetMoreInfo(
      input: {
        clientMutationId: ""
        fullName: $fullName
        email: $email
        phoneNumber: $phoneNumber
        otpCode: $otpCode
        propertyName: $propertyName
        visitDate: $visitDate
        utm: $utm
        projectsCode: $projectsCode 
        clusterCode: $clusterCode 
      }
    ) {
      status
      message
    }
  }
`

const GET_SHOW_WA_PROPERTY_TYPE = gql`
  query GetBannerPropertyType($slug: ID!) {
    propertyType(id: $slug, idType: SLUG) {
      acfPropertyTypeShowWA {
        showButton
      }
    }
  }
`

const GET_BANNER_PROPERTY_TYPE = gql`
  query GetBannerPropertyType($slug: ID!) {
    propertyType(id: $slug, idType: SLUG) {
      acfPropertyTypeBanner {
        bannerDesktop {
          link
          image {
            sourceUrl
          }
        }
        bannerMobile {
          image {
            sourceUrl
          }
          link
        }
      }
    }
  }
`

/* ==========================================================================
  Render Functions
   ========================================================================== */

const buttonScrollHandler = (target) => {
  var scrollContainer = target
  do { //find scroll container
    scrollContainer = scrollContainer.parentNode
    if (!scrollContainer) return
    scrollContainer.scrollTop += 1
  } while (scrollContainer.scrollTop == 0)

  var targetY = -60
  do { //find the top of target relatively to the container
    if (target == scrollContainer) break
    targetY += target.offsetTop
  } while (target = target.offsetParent)

  const scroll = function(c, a, b, i) {
    i++; if (i > 30) return
    c.scrollTop = a + (b - a) / 30 * i
    setTimeout(function(){ scroll(c, a, b, i); }, 20)
  }
  // start scrolling
  scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0)
}

const CustomFields = ({
  data,
  nearbyProjects,
  lat,
  long,
  nearbyData,
  eBrochureLink,
  project,
  lang,
  embedMaps,
  // eCatalogUrl,
}) => {
  // const { lang } = useContext(LangContext)
  if (data.attributes.text === "access-transportation") {
    return (
      <Section key={`access-transportation`}>
        <div className="mw-md">
          <HeadingBasic
            className="mb-4"
            h2={
              lang === "EN" ? "Access & Transportation" : "Akses & Transportasi"
            }
            text={[
              lang === "EN"
                ? "Sinarmas Land is the largest and most diversed property developer in Indonesia. The company is known for its rich experience of more than 40 years in the field of property development"
                : "Sinarmas Land adalah pengembang properti terbesar dan paling beragam di Indonesia. Perusahaan ini dikenal dengan pengalamannya selama lebih dari 40 tahun di bidang pengembangan properti ",
            ]}
          />
          <Link
            to="/development/township/bsd-city/toll-road"
            className="btn btn-outline-dark mt-3"
          >
            <span>
              <img alt="icToll" className="access-icon" src={icToll} />
              {lang === "EN" ? "Toll Road" : "Jalan Tol"}
            </span>
          </Link>
          <Link
            to="/development/township/bsd-city/train-station"
            className="btn btn-outline-dark mt-3"
          >
            <span>
              <img alt="icTrain" className="access-icon" src={icTrain} />
              {lang === "EN" ? "Train Station" : "Stasiun Kereta"}
            </span>
          </Link>
          <Link
            to="/development/township/bsd-city/bus-station"
            className="btn btn-outline-dark mt-3"
          >
            <span>
              <img alt="icBus" className="access-icon" src={icBus} />
              {lang === "EN" ? "Bus Station" : "Terminal Bus"}
            </span>
          </Link>
        </div>
      </Section>
    )
  } else if (data.attributes.text === "e-brochure") {
    return (
      <div className="e-brochure-access p-4 p-lg-5">
        <div>
          <div className="row">
            <div className="col-12 col-md mb-4 mb-md-0 d-flex justify-content-start align-items-center">
              <p className="text">
                {lang === "EN" ? "Let's calculate the estimated cost of your dream property." : "Ayo hitung perkiraan biaya properti impian Anda."}
              </p>
            </div>
            <div className="col-12 col-md-auto d-flex justify-content-center justify-content-md-end align-items-center">
              <Button
                variant="primary"
                className="btn-download-outline"
                onClick={() => buttonScrollHandler(document.getElementById('fin-pro'))}
              >
                {lang === "EN" ? "Calculate here" : "Hitung disini"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div></div>
}

const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading, lang }) => {
  // const lang = (window && window.location.pathname?.split('/')[1] === "id") ? "ID" : "EN"
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)
  return (
    <Form className="row pt-4">
      <div className="col-12 col-md-4 pb-4 pb-md-0">
        <label className="text-muted my-0">{lang === "EN" ? "Name" : "Nama"}</label>
        <Field
          name="name"
          className="form-control px-0"
          placeholder={lang === "EN" ? "Enter your name" : "Masukan nama anda"}
          type="text"
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="col-12 col-md-4 pb-4 pb-md-0">
        <label className="text-muted my-0">Email</label>
        <Field
          name="email"
          className="form-control px-0"
          placeholder={lang === "EN" ? "Enter your email" : "Masukan email anda"}
          type="text"
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="col-12 col-md-4">
        <label className="text-muted my-0">{lang === "EN" ? "Phone Number" : "Nomor Telepon"}</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control px-0"
          placeholder={lang === "EN" ? "Enter your phone number" : "Masukan nomor telepon anda"}
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={val =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
          type="tel"
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="col-12 pt-5">
        <button disabled={btnLoading} className="btn btn-primary w-100 w-md-25">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </button>
        <p className="pt-3 text-muted">
          {lang === "EN" ?
            "Sinar Mas Land will never sell or share your information with a third party"
            : "Sinar Mas Land tidak akan pernah menjual atau membagikan informasi Anda kepada pihak ketiga"
          }
        </p>
      </div>
    </Form>
  )
}

const LeadFormVertical = ({ errors, touched, values, setFieldValue, btnLoading, lang }) => {
  // const lang = (window && window.location.pathname?.split('/')[1] === "id") ? "ID" : "EN"
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)
  return (
    <Form className="lead-form-vertical">
      <p className="top-text text-lg-center mx-lg-auto">Please fill in the form below for more information about this project.</p>
      <div className="pb-2">
        <label className="my-0">{lang === "EN" ? "Your Name" : "Nama Anda"}</label>
        <Field
          name="name"
          className="form-control"
          placeholder={lang === "EN" ? "e.g Tiffany Kurniawati" : "contoh: Tia Kurniawati"}
          type="text"
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="pb-2">
        <label className="my-0">{lang === "EN" ? "Phone Number" : "Nomor Telepon"}</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control"
          placeholder={lang === "EN" ? "e.g +62 812 3456 7890" : "contoh +62 812 3456 7890"}
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={val =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
          type="tel"
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="pb-2">
        <label className="my-0">{lang === "EN" ? "Your Email" : "Email Anda"}</label>
        <Field
          name="email"
          className="form-control"
          placeholder={lang === "EN" ? "e.g user@example.com" : "contoh: user@example.com"}
          type="text"
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="pb-2 position-relative">
        <label className="my-0">{lang === "EN" ? "Book my Appointment" : "Tanggal Kunjungan"}</label>
        <Field
          name="visitDate"
          className="form-control"
          placeholder={lang === "EN" ? "site visit" : "kunjungan"}
          type="date"
          min={new Date().toISOString().split("T")[0]}
        />
        {/* <span className="position-absolute text-black-50" style={{ top: "30px", right: "10px" }}>
          <i className="fa fa-calendar-alt"></i>
        </span> */}
        {touched.visitDate && errors.visitDate && (
          <div className="invalid-value d-block">{errors.visitDate}</div>
        )}
      </div>
      <div className="text-center pt-3">
        <button disabled={btnLoading} className="btn btn-primary py-2 btn-submit">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "SUBMIT"}
        </button>
        <p className="pt-2 bottom-text mx-auto mb-0">
          {lang === "EN" ?
            "Sinar Mas Land will never sell or share your information with a third party"
            : "Sinar Mas Land tidak akan pernah menjual atau membagikan informasi Anda kepada pihak ketiga"
          }
        </p>
      </div>
    </Form>
  )
}

const Blocks = ({
  item,
  i,
  detail,
  nearby,
  projectDetail,
  eBrochureData,
  lang,
  mapsEmbedData,
}) => {
  switch (item?.name) {
    case "core/heading":
      return (
        <div key={`heading-${i}`}>
          <div className={i === 0 ? "py-main" : ""}>
            <HeadingBasic h2={item.attributes.content} className="text-red" />
          </div>
        </div>
      )
    case "core/buttons":
      if (
        item.innerBlocks[0].attributes.url &&
        item.innerBlocks[0].attributes.url.split(".").length > 1
      ) {
        return (
          <div key={`button=${i}`}>
            <div className={i === 0 ? "py-main" : "pb-main"}>
              {/* <HeadingBasic /> */}
              <a
                href={
                  item.innerBlocks[0].attributes.url.split("https://").length >
                    1
                    ? item.innerBlocks[0].attributes.url
                    : `https://${item.innerBlocks[0].attributes.url}`
                }
                target="_blank"
                className="btn btn-link font-size-lg fx-underline"
                rel="noopener noreferrer"
                key={`${item.innerBlocks[0].attributes.url}-${i}`}
              >
                {item.innerBlocks[0].attributes.text} &nbsp;
                <i className="far fa-external-link"></i>
              </a>
            </div>
          </div>
        )
      } else {
        return (
          <div key={`button-${i}`}>
            <Link
              key={`${item.innerBlocks[0].attributes.url}-${i}`}
              to={item.innerBlocks[0].attributes.url}
              className="btn btn-link font-size-lg fx-underline"
            >
              {item.innerBlocks[0].attributes.text}
            </Link>
          </div>
        )
      }
    case "core/paragraph":
      return (
        <Section key={item.attributes.content} className="pb-3 pt-0">
          <div className="mw-lg">
            <HeadingBasic text={[item.attributes.content]} />
          </div>
        </Section>
      )
    case "core/group":
      return (
        <div
          key={`${item.name}-${i}`}
          className={i === 0 || i === 1 ? "py-main pt-3 pb-3" : "pb-main pt-3 pb-3"}
        >
          {/* <WpGroup
            data={item}
          // path={`/development/${projectDetail?.projectBy.propertyTypes.nodes[0].slug}`}
          /> */}

          <WpGroupProjectDetail
            data={item}
            lang={lang}
          />
        </div>
      )
    case "acf/iconlist":
      return (
        <div
          key={`iconlist-${i}`}
          className={i === 0 ? "py-main" : "pb-main"}
        >
          <WpIconlistNew data={item} />
        </div>
      )
    case "acf/labelvalues":
      let labelValuesPb
      if (
        detail.blocks[i + 1] !== undefined &&
        detail.blocks[i + 1]?.attributes !== undefined &&
        detail.blocks[i + 1]?.attributes.text === "map-location"
      ) {
        labelValuesPb = false
      } else {
        labelValuesPb = true
      }
      return (
        <div
          key={`${item.name}-${i}`}
          className={labelValuesPb ? "pb-main" : ""}
        >
          <Stats data={item} />
        </div>
      )
    case "core/shortcode":
      let pbMain
      if (
        detail.blocks[i + 1] !== undefined &&
        detail.blocks[i + 1]?.attributes !== undefined &&
        (detail.blocks[i + 1]?.attributes.text === "calculator-finance" ||
          detail.blocks[i + 1]?.attributes.text === "e-brochure")
      ) {
        pbMain = false
      } else if (i === detail.blocks.length - 1) {
        pbMain = false
      } else {
        pbMain = true
      }
      return (
        item.attributes.text !== "lead-form" && 
        item.attributes.text !== "map-location" && 
        item.attributes.text !== "calculator-finance" && 
        item.attributes.text !== "carousel-nearby"  ? (
          <section
            key={`cutom_fields-${i}`}
            className={pbMain ? "pb-main" : "pb-md-0 pb-3"}
          >
            <CustomFields
              data={item}
              nearbyProjects={nearby}
              lat={detail?.projectCustomfields?.latitude}
              long={detail?.projectCustomfields?.longitude}
              pre={projectDetail?.projectBy?.propertyTypes?.nodes?.[0]?.slug}
              nearbyData={projectDetail?.projectBy?.nearby}
              pageTitle={projectDetail?.projectBy?.title}
              project={projectDetail}
              eBrochureLink={
                eBrochureData?.eBrochure?.eBrochure?.file?.mediaItemUrl
              }
              lang={lang}
              embedMaps={mapsEmbedData}
              // eCatalogUrl={eCatalogUrlDetail}
            />
          </section>
        ) : null
      )
    default:
      return <div key={`empty-element-${i}`}></div>
  }
}

const currencyFormat = (money) => {
  return new Intl.NumberFormat('id-ID',
    { minimumFractionDigits: 0 }
  ).format(money);
}

const SideCard = ({ projectPrice, isHasForm, formComponent, callbackComponent }) => {
  const isHasPrice = projectPrice !== null && projectPrice !== 0 && projectPrice > 0;

  if(!isHasForm){
    return (
      <div className="project-detail-sticky mb-5">
        {callbackComponent}
      </div>
    )
  }
  return (
    <div className="project-detail-sticky mb-5">
      <div class="top-part p-4">
        <div class="top-title text-white">{isHasPrice? 'Start From :' : 'Enjoy Sinar Mas Land Special Offer'}</div>
        { isHasPrice ? (
          <div class="top-price text-white text-center">
            Rp. {currencyFormat(projectPrice)}
          </div>
        ) : null }
      </div>
      <div className="p-4">
        {formComponent}
      </div>
    </div>
  )
}

const ContentTopPrice = ({ projectPrice, isHasForm, lang }) => {
  const isHasPrice = projectPrice !== null && projectPrice !== 0 && projectPrice > 0;

  if(!isHasForm) return null
  return (
    <div className="content-top-price">
      <div class="top-title">{isHasPrice? 'Start from :' : 'Enjoy Sinar Mas Land Special Offer'}</div>
      <div class="top-price">
        <a onClick={() => buttonScrollHandler(document.getElementById('gmi'))}>
          { isHasPrice ? `Rp. ${currencyFormat(projectPrice)}` : lang === "EN" ? "Reach Us Now!" : "Hubungi Kami Sekarang!" }
        </a>
      </div>
    </div>
  )
}

/* ==========================================================================
   ========================================================================== */

/* ==========================================================================
  Render
   ========================================================================== */

const ProjectDetail = ({ pageContext, location }) => {
  const {
    slug,
    title,
    projectCode,
    clusterCode,
    translation,
    lang,
    seo,
    leadSourceName,
    priceProduct,
    eCatalogProject,
    propertyTypes,
    mapsEmbedUrl,
    featuredImage,
    projectCustomfields,
  } = pageContext

  // const { lang } = useContext(LangContext)
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    visitDate: "",
  })
  const [whatsappModal, setWhatsappModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [otp, setOtp] = useState()
  const [successModal, setSuccessModal] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [timeLimit, setTimeLimit] = useState(180)
  // const [attentionModal, setAttentionModal] = useState(false)
  // const [hasVisitor, setHasVisitor] = useState(false)

  // server-side --- static site generation
  const staticData = useStaticQuery(STATIC_IMAGE)

  // client-side --- dynamic fetching
  const { loading: intialLoading, data: projectDetail } = useQuery(
    GET_PROJECT_DETAIL,
    {
      variables: { slug },
    }
  )

  const { loading: isLoadingBanner, data: banner } = useQuery(
    GET_BANNER_PROPERTY_TYPE,
    { variables: { slug: (lang === "EN" ? propertyTypes : translation?.propertyTypes?.nodes?.[0]?.slug) ?? propertyTypes } }
  )

  const { loading: eBrochureLoading, data: eBrochureData } = useQuery(
    FETCH_EBROCHURE,
    {
      variables: { id: slug },
    }
  )

  const { loading: isLoadingShowWA, data: showWA } = useQuery(
    GET_SHOW_WA_PROPERTY_TYPE,
    { variables: { slug: propertyTypes } }
  )

  const [getNearby, { data }] = useLazyQuery(GET_NEARBY_PROJECT)
  const [getOtp, { loading: getOtpLoading }] = useMutation(GET_OTP)
  const [submitOtp, { loading: submitOtpLoading }] = useMutation(SUBMIT_OTP)

  // data variables
  const detail = lang === "EN" ? projectDetail?.projectBy : translation || projectDetail?.projectBy
  const cover =
    lang === "EN"
      ? pageContext &&
      pageContext?.blocks &&
      pageContext?.blocks.find(item => item.name === "core/cover")
      : translation?.blocks.find(item => item.name === "core/cover") ||
      pageContext?.blocks.find(item => item.name === "core/cover")
  const nearby = data ? data.projects.edges : null
  const projectByTypes = data
    ? data.propertyTypes.nodes[0].projects.nodes
    : null

  const pageSeo = seo ? seo : pageContext.translation.seo

  const thisCurrrentUrl = process.env.BASE_URL + location.pathname

  let programmaticProjectList = ["terravia", "nordville"]

  const gtagFunction = (...data) => {
    return window.dataLayer.push(...data)
  }

  const gtag_tracking_conversion = (url) =>{
    const callback = () => {
      if (typeof (url) != "undefined") {
        window.location = url
      }
    }

    gtagFunction("event", "conversion", { 
      "send_to" : `AW-${process.env.CONVERSION_ID}/${process.env.CONVERSION_LABEL}`,
      'event_callback': callback
     })
    return false
  }

  useEffect(() => {
    if (projectDetail && !data) {
      getNearby({
        variables: {
          id: projectDetail?.projectBy?.nearby,
          slug: projectDetail?.projectBy?.propertyTypes?.nodes?.[0]?.slug,
          // Change to static value. Because in lang ID, slug and projectType slug is not same with the EN version, and it will break the link to the project detail.
          language: "EN",
          notId: projectDetail?.projectBy?.projectId,
        },
      })
      productDetailDataLayer()
    }
  }, [projectDetail])

  useEffect(() => {
    let timer = setTimeout(() => {
      if (timeLimit >= 0 && showModal) {
        setTimeLimit(timeLimit - 1)
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [timeLimit, showModal])

  useEffect(() => {
    const modalElement = document.querySelectorAll('.ReactModalPortal');

    if (modalElement.length > 0) {
      for (var i = 0; i < modalElement.length; i++) {
        modalElement[i].removeAttribute('inert')
        modalElement[i].removeAttribute('aria-hidden')
      }
    }

    return () => {
      if (modalElement.length > 0) {
        for (var i = 0; i < modalElement.length; i++) {
          modalElement[i].removeAttribute('inert')
          modalElement[i].removeAttribute('aria-hidden')
        }
      }
    };
  }, [])

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)
      const formatNumber = values.phoneNumber.split("+").join("")
      getOtp({ variables: { phoneNumber: formatNumber } }).then(res => {
        if (res.data.requestOtp.status === "success") {
          setShowModal(true)
        }
      })
    },
  })((props) => LeadForm({ ...props, lang }))

  const EnhancedLeadFormVertical = withFormik({
    mapPropsToValues({ name, phoneNumber, email, visitDate }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        visitDate: formValues.visitDate || visitDate,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      visitDate: Yup.string().required("Visit date is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)
      const formatNumber = values.phoneNumber.split("+").join("")
      getOtp({ variables: { phoneNumber: formatNumber } }).then(res => {
        if (res.data.requestOtp.status === "success") {
          setShowModal(true)
        }
      })
    },
  })((props) => LeadFormVertical({ ...props, lang }))

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "888889",
    },
  }

  const handleCloseWhatsappModal = () => {{
    setWhatsappModal(false)
  }}

  const handleWhatsappModal = () => {{
    setWhatsappModal(true)

    const modalElement = document.querySelectorAll('.ReactModalPortal');

    if (modalElement.length > 0) {
      for (var i = 0; i < modalElement.length; i++) {
        modalElement[i].removeAttribute('inert')
        modalElement[i].removeAttribute('aria-hidden')
      }
    }
  }}

  const handleSuccessModal = () => {{
    if(eCatalogProject?.url){
      window.open(eCatalogProject?.url, "_blank")
    }else{
      window.location.reload()
    }
    setSuccessModal(false)
  }}

  const handleOtp = otp => {
    setOtp(otp)
  }

  const getOtpHandler = () =>
    getOtp({
      variables: { phoneNumber: formValues.phoneNumber.split("+").join("") },
    }).then(res => {
      if (res.data.requestOtp.status === "success") {
        setTimeLimit(180)
        setShowModal(true)
      }
    })

  const sendOtp = () => {
    const formatNumber = formValues.phoneNumber.split("+").join("")

    submitOtp({
      variables: {
        fullName: formValues.name,
        email: formValues.email,
        phoneNumber: formatNumber,
        visitDate: formValues.visitDate,
        otpCode: otp,
        propertyName: title + " - SMLWebsite",
        utm: extractCookie("utm_params=")
          ? JSON.stringify(extractCookie("utm_params="))
          : null,
        projectsCode: projectCode ? projectCode : "",
        clusterCode: clusterCode ? clusterCode : "",
      },
    }).then(res => {
      if (res.data.submit2FormGetMoreInfo.status === "success") {
        if (programmaticProjectList.includes(slug)) {
          gtag_tracking_conversion(thisCurrrentUrl)
        }
        setDetailDataLayer(setDataLayerValue("InitiateCheckout"))
        sessionStorage.setItem(
          "purchaseDataLayer",
          JSON.stringify(setDataLayerValue("Purchase", {
            value: priceProduct ?? projectDetail?.projectBy?.priceProduct?.price ?? 0,
          }))
        )
        setShowModal(false)
        /* setSuccessModal(true) */
        /* setTimeLimit(180) */
        setFormValues({ fullName: "", email: "", phoneNumber: "", visitDate: "" })
        setOtp("")
        if (otpError) {
          setOtpError(false)
        }
        submitUtm({
          fullname: formValues.name ?? '',
          email: formValues.email ?? '',
          mobile: formValues.phoneNumber ?? '',
          visit_date: formValues.visitDate ?? '',
          project_name: title ?? '',
          projects_code: projectCode ?? '',
          cluster_code: clusterCode ?? '',
          lead_source_name: leadSourceName ?? '',
        },()=>{
          // redirect to thank you
          // navigate('/thankyou/')
          window.location.href = "/thankyou/";
        })
      } else {
        setOtpError(true)
      }
    })
  }

  const sendOtpJvProduct = () => {
    const formatNumber = formValues.phoneNumber.split("+").join("")

    submitOtp({
      variables: {
        fullName: formValues.name,
        email: formValues.email,
        phoneNumber: formatNumber,
        visitDate: formValues.visitDate,
        otpCode: otp,
        propertyName: title + " - SMLWebsite",
        utm: extractCookie("utm_params=")
          ? JSON.stringify(extractCookie("utm_params="))
          : null,
        projectsCode: projectCode ? projectCode : "",
        clusterCode: clusterCode ? clusterCode : "",
      },
    }).then(res => {
      if (res.data.submit2FormGetMoreInfo.status === "success") {
        if (programmaticProjectList.includes(slug)) {
          gtag_tracking_conversion(thisCurrrentUrl)
        }
        setDetailDataLayer(setDataLayerValue("InitiateCheckout"))
        sessionStorage.setItem(
          "purchaseDataLayer",
          JSON.stringify(setDataLayerValue("Purchase", {
            value: priceProduct ?? projectDetail?.projectBy?.priceProduct?.price ?? 0,
          }))
        )
        setShowModal(false)
        /* setSuccessModal(true) */
        /* setTimeLimit(180) */
        setFormValues({ fullName: "", email: "", phoneNumber: "" })
        setOtp("")
        if (otpError) {
          setOtpError(false)
        }
        submitUtm({
          fullname: formValues.name ?? '',
          email: formValues.email ?? '',
          mobile: formValues.phoneNumber ?? '',
          visit_date: formValues.visitDate ?? '',
          project_name: title ?? '',
          projects_code: projectCode ?? '',
          cluster_code: clusterCode ?? '',
          lead_source_name: leadSourceName ?? '',
        },()=>{
          // redirect to thank you
          // navigate('/thankyou/')
          window.location.href = "/thankyou/";
        })
      } else {
        setOtpError(true)
      }
    })
  }

  const setDataLayerValue = (event, otherValue) => {
    const value = {
      event: event,
      page_url: window.location.href,
      page_title: title,
      content_type: "home_listing",
      content_ids: slug.replaceAll("-", "_"),
      city: projectDetail?.projectBy?.locations?.nodes[0]?.slug,
      region: projectDetail?.projectBy?.locations?.nodes[0]?.slug,
      country: "Indonesia",
      lease_start_date: "2021-01-01",
      lease_end_date: "2100-01-01",
      currency: "IDR",
      preferred_price_range: [
        projectDetail?.projectBy?.projectCustomfields?.preferredPriceRangeFrom,
        projectDetail?.projectBy?.projectCustomfields?.preferredPriceRangeTo,
      ],
      preferred_baths_range: [
        projectDetail?.projectBy?.projectCustomfields?.preferredBathsRangeFrom ?? 0,
        projectDetail?.projectBy?.projectCustomfields?.preferredBathsRangeTo ?? 0,
      ],
      preferred_bed_range: [
        projectDetail?.projectBy?.projectCustomfields?.preferredBedRangeFrom ?? 0,
        projectDetail?.projectBy?.projectCustomfields?.preferredBedRangeTo ?? 0,
      ],
      property_type: propertyTypes,
      ...otherValue,
    }
    return value
  }

  const setDetailDataLayer = value => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(value)
  }

  const productDetailDataLayer = () => {
    setDetailDataLayer(setDataLayerValue("ViewContent"))
  }

  // const pageSeo = seo ? seo : pageContext.translation.seo

  const NearbyProjectsSection = () => {
    const excludePropertyType = (arr)=>{
      if(!arr) return [{slug: null}]
      return arr.filter((item)=>item.slug !== 'infiniteliving')
    }

    const nearbyDataSection = nearby.map(item => ({
      image: item.node?.featuredImage?.sourceUrl ?? "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg",
      propertyName: item.node?.title ?? '',
      location: item.node?.locations?.nodes?.[0]?.name ?? null,
      description: item.node?.excerpt ?? null,
      /* linkTo: item.node.propertyTypes.nodes[0].slug === "properti"
        ? `/wishforhome/${item.node?.locations?.nodes[0]?.slug}/${item.node.slug}`
        : item.node.propertyTypes.nodes[0].slug === "kavling"
          ? `/wishforhome/${item.node?.propertyTypes?.nodes[0]?.slug}/${item.node?.locations?.nodes[0]?.slug}/${item.node.slug}`
          : `/development/${excludePropertyType(item.node?.propertyTypes?.nodes)[0]?.slug}/${item.node.slug}`, */
      linkTo: `${lang === 'EN' ? '' : String("/"+lang).toLowerCase()}/development/${excludePropertyType(item.node?.propertyTypes?.nodes)[0]?.slug ?? ''}/${item.node?.slug ?? ''}`,
      type: item.node?.propertyTypes?.nodes?.[0]?.name ?? null,
      facilities: item.node?.facilities?.nodes?.slice(0, 3) ?? null
    }));

    return (
      <div className="px-sm-down-0 mb-md-3">
        <Glider id="nearby" data={nearbyDataSection} version="2" />
      </div>
    )
  }

  const isHasCalculatorShortCode = !!projectDetail?.projectBy?.blocks?.find((block)=>block.name === "core/shortcode" && block.attributes.text === "calculator-finance");
  const isHasLeadFormShortCode = !!projectDetail?.projectBy?.blocks?.find((block)=>block.name === "core/shortcode" && block.attributes.text === "lead-form");

  const [projectMap, setProjectMap] = useState(null)
  const [projectPhoto, setProjectPhoto] = useState(null)
  const [projectType, setProjectType] = useState([])
  const [projectVirtualTour, setProjectVirtualTour] = useState(null)
  const [projectVideoTour, setProjectVideoTour] = useState(null)
  const [projectVR360, setProjectVR360] = useState(null)

  useEffect(() => {
    setProjectMap(projectDetail?.projectBy?.embedProjectsLocation?.embedUrl)
    setProjectPhoto(projectDetail?.projectBy?.blocks?.find((block) => block.name === "acf/acfgallery"))
    setProjectType(projectDetail?.projectBy?.projectCustomfields?.projectTypeBlueprint)
    setProjectVirtualTour(projectDetail?.projectBy?.blocks?.find((block) => block.name === "acf/virtual-link"))
    setProjectVideoTour(projectDetail?.projectBy?.blocks?.find((block) => block.name === "core/embed"))
    setProjectVR360(projectDetail?.projectBy?.embedProjectsVR360?.vr360EmbedUrl)
  },[projectDetail])

  const isHasMediaTab = !!projectMap || !!projectPhoto || !!projectVirtualTour || !!projectVideoTour || !!projectVR360
  const arrayMediaTab = [
    ...!!projectMap ? [{
      name: "Map",
      target: "map",
    }] : [],
    ...!!projectPhoto ? [{
      name: "Photo",
      target: "photo",
    }] : [],
    ...!!projectVR360 ? [{
      name: "VR360",
      target: "vr360",
    }] : [],
    ...!!projectVirtualTour ? [{
      name: "Virtual Tour",
      target: "virtual-tour",
    }] : [],
    ...!!projectVideoTour ? [{
      name: "Video Tour",
      target: "video-tour",
    }] : [],
  ]

  const MediaTabs = () => {
    return (
      <>
        <NavTabs
          scrollable
          identifier="media-tab"
          outerClassname="project-detail-tabs"
          data={arrayMediaTab}
        />
        <>
          {!!projectMap ?
            <TabPane id="map" className={arrayMediaTab.findIndex(item => item.target === "map") === 0 ? "active" : ""} identifier="media-tab">
              <GoogleMapsEmbed embedUrl={projectMap} className="media-content-maps" />
            </TabPane> 
            : null
          }
          {!!projectPhoto ?
            <TabPane id="photo" className={arrayMediaTab.findIndex(item => item.target === "photo") === 0 ? "active" : ""} identifier="media-tab">
              <div className="media-content-photo">
                <Carousel data={projectPhoto} withLightbox={true} withButtonWa={handleWhatsappModal}/>
              </div>
            </TabPane> 
            : null
          }
          {!!projectVR360 ?
            <TabPane id="vr360" className={arrayMediaTab.findIndex(item => item.target === "vr360") === 0 ? "active" : ""} identifier="media-tab">
              <div 
                className={"media-content-maps"} 
                dangerouslySetInnerHTML={{__html: projectVR360}} 
              ></div>
            </TabPane> 
            : null
          }
          {!!projectVirtualTour ?
            <TabPane id="virtual-tour" className={arrayMediaTab.findIndex(item => item.target === "virtual-tour") === 0 ? "active" : ""} identifier="media-tab">
              <div className="overflow-x-hidden">
                <GliderVirtualLinkNew data={projectVirtualTour.acf.cards.map((item) => ({
                  image: item.image
                    ? item.image.mediaItemUrl
                    : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg",
                  propertyName: item.title,
                  description: item.description || null,
                  linkTo: item.link && item.link.url,
                  target: item.link && item.link.target,
                }))} />
              </div>
            </TabPane> 
            : null
          }
          {!!projectVideoTour ?
            <TabPane id="video-tour" className={arrayMediaTab.findIndex(item => item.target === "video-tour") === 0 ? "active" : ""} identifier="media-tab">
              <Cover
                variant="basic"
                theme="dark"
                img={null}
                imgHeight="media-content-video"
                videoSrc={projectVideoTour.attributes?.url}
                type={{ name: "core-embed/youtube" }}
                audio={false}
              />
            </TabPane>
            : null
          }
        </>
      </>
    )
  }

  const [shareModal, setShareModal] = useState(false)
  const onCloseShareModal = () => {{
    setShareModal(false)
  }}

  const onCopyLink = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  const coverText = useMemo(() => {
    const type = projectDetail?.projectBy?.propertyTypes?.nodes[0].name ? <span>{projectDetail?.projectBy?.propertyTypes?.nodes[0].name}</span> : '';
    const locations = projectDetail?.projectBy?.locations?.nodes[0].name ? <span>
      <i className="fas fa-map-marker-alt mr-1"></i> {projectDetail?.projectBy?.locations?.nodes[0].name}
    </span> : '';
    return <>{type} | {locations}</>;
  }, [projectDetail])

  return (
    <Layout location={location}
      // hideFooter={detail && nearby && !intialLoading ? false : true}
      lang={lang}
      isMicrodataProduct={true}
      isWaForm={showWA?.propertyType?.acfPropertyTypeShowWA?.showButton ?? false}
      waFormClick={()=>setWhatsappModal(true)}
    >
      <SEO
        title={pageSeo?.title}
        fbTitle={pageSeo?.opengraphTitle || pageSeo?.title}
        twitterTitle={pageSeo?.twitterTitle || pageSeo?.title}
        twitterDescription={pageSeo?.twitterDescription || seo?.opengraphDescription}
        fbDescription={pageSeo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={thisCurrrentUrl}
        fbImg={
          featuredImage
        }
        twitterImg={
          featuredImage
        }
        img={
          featuredImage
        }
        // schemaMarkup={schemaDetailProjects}
      />
      <div className="project-detail">
        <Helmet>
          <body className="bd-page" />
          <meta name="keywords" content={pageSeo?.focuskw ? pageSeo?.focuskw : ""}></meta>
          <meta content="og:product" property="og:type"></meta>
        </Helmet>

        {cover && (
          <CoverMain
            label={lang === "EN" ? "Welcome To" : "Selamat Datang Di"}
            h1={
              lang === "EN"
                ? detail?.title
                : translation?.title || detail?.title
            }
            img={
              cover !== undefined
                ? cover.attributes.url
                : staticData.imgCoverMain.childImageSharp.fluid
            }
            alt={detail?.title}
            className="product-detail-banner"
            text={coverText}
          >
            <Breadcrumbs
              path={location.pathname}
              className="breadcrumb-light"
            />
          </CoverMain>
        )}
        <Container className={'pt-3'}>
          <div class="row">
            <div class="col col-lg-8">
              <div>
                <meta itemProp="mpn" content="925872" />
                <meta itemProp="name" content={pageSeo?.title} />
                <link itemProp="image" href={cover?.attributes?.url} />
                <meta itemProp="description" content={seo?.opengraphDescription} />
                <div
                  itemProp="offers"
                  itemType="https://schema.org/AggregateOffer"
                  itemScope
                >
                  <meta itemProp="lowPrice" content={priceProduct ? priceProduct : 0} />
                  <meta
                    itemProp="highPrice"
                    content={priceProduct ? priceProduct : 0}
                  />
                  <meta itemProp="offerCount" content="10" />
                  <meta itemProp="priceCurrency" content="IDR" />
                </div>
                <div itemProp="review" itemType="https://schema.org/Review" itemScope>
                  <div itemProp="author" itemType="https://schema.org/Person" itemScope>
                    <meta itemProp="name" content="Sinar Mas Land" />
                  </div>
                  <div
                    itemProp="reviewRating"
                    itemType="https://schema.org/Rating"
                    itemScope
                  >
                    <meta itemProp="ratingValue" content="4.5" />
                    <meta itemProp="bestRating" content="5" />
                  </div>
                </div>
                <meta itemProp="sku" content="0446310786" />
                <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
                  <meta itemProp="name" content="Sinar Mas Land" />
                </div>
              </div>
              <div className="mb-2 mt-3">
                <div className="d-flex flex-column flex-lg-row-reverse justify-content-between align-items-lg-center">
                  <div className="price-box mb-3">
                    <div className="d-lg-none">
                      <ContentTopPrice 
                        isHasForm={isHasLeadFormShortCode} 
                        projectPrice={priceProduct || projectDetail?.projectBy?.priceProduct?.price} 
                        lang={lang}
                      />
                    </div>
                    <button onClick={() => setShareModal(true)} className="share-trigger">
                      <i class="fas fa-share-alt"></i>
                    </button>
                  </div>
                  <h2 className="project-detail-title mb-1">Highlight</h2>
                </div>
              </div>
              <div className="project-detail-content">
                {detail?.blocks &&
                  detail?.blocks.map((item, i) => {
                    return (
                      <Blocks
                        item={item}
                        i={i}
                        detail={detail}
                        EnhancedLeadForm={EnhancedLeadForm}
                        getOtpLoading={getOtpLoading}
                        nearby={nearby}
                        projectDetail={projectDetail}
                        eBrochureData={eBrochureData}
                        lang={lang}
                        mapsEmbedData={mapsEmbedUrl}
                        // eCatalogUrlDetail={() => {setAttentionModal(true)}}
                        withButtonWa={handleWhatsappModal}
                      />
                    );
                  })
                }
              </div>

              { isHasMediaTab ?
                <div className="pb-main">
                  <MediaTabs />
                </div>
              : null }
              { projectType ?
                <div>
                  <h2 className="text-red font-weight-bolder m-0">Unit Type</h2>
                  <div className="margin-container">
                    <UnitTypes data={projectType} />
                  </div>
                </div>
              : null }
              {isHasLeadFormShortCode ? 
                <div className="d-lg-none pt-main project-detail-form" id="gmi">
                  <h2 className="text-red font-weight-bolder mb-3 mb-lg-0">Get More Information</h2>
                  <p className="mb-0">Find out more about this project</p>
                  <EnhancedLeadFormVertical btnLoading={getOtpLoading} />
                </div>
              : banner?.propertyType?.acfPropertyTypeBanner?.bannerMobile?.image?.sourceUrl ? (
                <div className="d-lg-none pt-main">
                  <a href={banner?.propertyType?.acfPropertyTypeBanner?.bannerMobile?.link ?? "#"} target="_blank">
                    <img 
                      src={banner?.propertyType?.acfPropertyTypeBanner?.bannerMobile?.image?.sourceUrl} 
                      alt="Sinar Mas Land" 
                      style={{
                        display: "block",
                        height: "auto", 
                        width: "100%"
                      }}
                    />
                  </a>
                </div>
              ) : null}
              { isHasCalculatorShortCode ?
                <div id="fin-pro">
                  <CalculatorFinance key={`calculator-finance`} />
                </div>
              : null }
            </div>
            <div class="col d-none d-lg-block">
              <SideCard 
                isHasForm={isHasLeadFormShortCode} 
                projectPrice={priceProduct || projectDetail?.projectBy?.priceProduct?.price} 
                formComponent={<EnhancedLeadFormVertical btnLoading={getOtpLoading} />}
                callbackComponent={
                  banner?.propertyType?.acfPropertyTypeBanner?.bannerDesktop?.image?.sourceUrl ? (
                    <a href={banner?.propertyType?.acfPropertyTypeBanner?.bannerDesktop?.link ?? "#"} target="_blank">
                      <img 
                        src={banner?.propertyType?.acfPropertyTypeBanner?.bannerDesktop?.image?.sourceUrl} 
                        alt="Sinar Mas Land" 
                        style={{
                          display: "block",
                          height: "auto", 
                          width: "100%"
                        }}
                      />
                    </a>
                  ) : null
                }
              />
            </div>
          </div>
        </Container>
        {(nearby && nearby.length > 0) ? 
          <section className="overflow-x-hidden">
            <Container className="py-main">
              <h2 className="text-red font-weight-bolder">Nearby</h2>
              <NearbyProjectsSection />
            </Container>
          </section> 
        : null}
        <Modal
          isOpen={whatsappModal}
          onRequestClose={handleCloseWhatsappModal}
          style={customStyles}
          contentLabel="campaign modal"
          overlayClassName="modal-overlay-general"
        >
          <div className="modal-whatsapp-form">
            <i className="fal fa-times" onClick={handleCloseWhatsappModal}></i>
            <div className="container py-5 mw-100 mw-md-75">
              <EnhancedLeadForm />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={showModal}
          style={customStyles}
          contentLabel="campaign modal"
          overlayClassName="modal-overlay-general"
        >
          <div className="modal-popup-otp modal-body text-center">
            <div className="container py-5 mw-md-75 mw-100">
              <h2>OTP</h2>
              <p className="m-0">
                To proceed, please enter the 6-digits OTP code sent to your
                mobile number
              </p>
              <p className="font-weight-bold">{formValues.phoneNumber}</p>
              <OtpInput
                value={otp}
                containerStyle="otp-container"
                inputStyle={`otp-input ${otpError ? "error" : ""}`}
                onChange={handleOtp}
                numInputs={6}
                isInputNum
              />
              {otpError && (
                <p className="otp-error mt-3">
                  The code you entered does not match, please re-enter.
                </p>
              )}
              {getOtpLoading ? (
                <p className="mt-md-5 mt-3 text-primary">
                  <i className="fa fa-spinner fa-spin"></i>
                </p>
              ) : timeLimit > 0 ? (
                <p className="mt-md-5 mt-3 text-muted">
                  Haven’t received OTP code? Resend ({timeLimit})
                </p>
              ) : (
                <button
                  className="btn mt-md-5 mt-3 text-primary"
                  onClick={getOtpHandler}
                >
                  Haven’t received OTP code? <strong>Resend</strong>
                </button>
              )}
              <button
                disabled={submitOtpLoading}
                className={`btn btn-primary w-50 mt-3 ${projectCode === "JV" ? "JV" : ""}`}
                onClick={projectCode === "JV" ? sendOtpJvProduct : sendOtp}
              >
                {submitOtpLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={successModal}
          onRequestClose={handleSuccessModal}
          style={customStyles}
          contentLabel="campaign modal"
          overlayClassName="modal-overlay-general"
        >
          <div className="modal-popup-otp modal-body text-center">
            <div className="container py-5 mw-100 mw-md-75">
              {/* <img alt="success" src={icSuccess} className="mw-25 mb-4" /> */}
              <h2>OTP Verified!</h2>
              <p className="m-0">
                Your data has been submitted. Our Sinar Mas Land team will reach
                out to you.
              </p>
                <button
                  className="btn btn-ecatalog-theme mt-4"
                  onClick={handleSuccessModal}
                >
                  Visit e-Catalog
                </button>
              {/* {hasVisitor ? (
                <button
                  className="btn btn-ecatalog-theme mt-4"
                  onClick={handleSuccessModal}
                >
                  Visit e-Catalog
                </button>
              ) : (
                <button
                  className="btn btn-primary w-50 mt-4"
                  onClick={handleSuccessModal}
                >
                  Ok
                </button>
              )} */}
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={shareModal}
          onRequestClose={onCloseShareModal}
          style={customStyles}
          contentLabel="share modal"
          overlayClassName="modal-overlay-general"
        >
          <div className="share-modal-wrapper">
            <div className="share-modal-header">
              <h3>Share This Project</h3>
              <button onClick={()=> onCloseShareModal()} className="share-modal-close">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div className="share-modal-body">
                <span>Share the link via...</span>
                <div className="share-modal-social">
                  <TwitterShareButton url={thisCurrrentUrl}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className="svg-twitter-icon">
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                    </svg>
                  </TwitterShareButton>
                  <LinkedinShareButton url={thisCurrrentUrl}>
                    <i className="fab fa-linkedin"></i>
                  </LinkedinShareButton>
                  <FacebookShareButton url={thisCurrrentUrl}>
                    <i className="fab fa-facebook-square"></i>
                  </FacebookShareButton>
                </div>
                <span>Or copy link</span>
                <div className="share-modal-copy">
                  <i class="far fa-link"></i>
                  <input type="text" value={thisCurrrentUrl} readOnly />
                  <button className="btn" onClick={()=>onCopyLink(thisCurrrentUrl)}>Copy</button>
                </div>
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  )
}

export default ProjectDetail
